import $ from "../jquery";

/**
 * Trigger a hash click if necessary.
 *
 * @param {string} hash
 * @param {string} className
 */
function hashClick(hash, className) {
  if (hash) {
    const $target = $(hash);
    if ($target.hasClass(className)) {
      $target.click();
      return true;
    }
  }
}

/**
 * Initialize all active hash clicks.
 *
 * @param {string} sel selector for hash targets
 * @param {string} triggerSel selector for triggering elements
 * @param {() => {}} handler callback handler
 */
export default function initHashClicks(sel, triggerSel, handler) {
  const className = sel.substr(1);

  $(triggerSel).click(function (e) {
    const a = e.currentTarget;
    const loc = window.location;

    // are we already on the right page?
    if (
      a.origin === loc.origin &&
      a.pathname === loc.pathname &&
      hashClick(a.hash, className)
    ) {
      if (handler) handler();
    }
  });

  hashClick(window.location.hash, className);
}
