import $ from "../jquery";
import { getRecaptchaToken } from "./recaptcha";

/**
 * Collect all data from the form.
 *
 * @param {HTMLFormElement} form containing form
 * @returns {Promise<FormData>}
 */
function getFormData(form) {
  const post = new FormData(form);

  if (form.classList.contains("js-add-recaptcha")) {
    return getRecaptchaToken().then((token) => {
      post.append("recaptcha", token);
      return post;
    });
  }

  return Promise.resolve(post);
}

/**
 * Initialize all ajax forms.
 *
 * @param {string} sel selector
 */
export default function initAjaxForms(sel) {
  $(sel).each(function () {
    const form = this;
    const $form = $(form);
    const $fields = $form.find("input, select, textarea");
    const url = $form.attr("action");
    var $first = null;

    var $feedback = $form.find(".message");
    if (!$feedback.length) {
      $feedback = $('<div class="message"></div>');
      $form.prepend($feedback);
    }

    function showErrors(errors) {
      for (var key in errors) {
        const value = errors[key];

        const query = `[name="${value}"]`;
        const $field = $fields.filter(query);

        $field.addClass("error").parent().addClass("error");

        if (!$first) $first = $field;
      }
    }

    $form.submit((e) => {
      e.preventDefault();
      $form.find(".error").removeClass("error");
      $feedback.text("").removeClass("success error");

      getFormData(form).then((post) => {
        $.ajax({
          url: url,
          type: "POST",
          processData: false,
          contentType: false,
          cache: false,
          data: post,
          enctype: "multipart/form-data",
          success: function (data, status) {
            if (status === "success") {
              if (data.errors) {
                $first = null;
                showErrors(data.errors);

                if ($first) {
                  $first.focus();
                }
              }

              if (data.message) {
                $feedback
                  .text(data.message)
                  .addClass(data.success ? "success" : "error");
              }

              if (data.success) {
                form.reset();

                if (window.gtag) {
                  window.gtag('event', 'submit', {
                    event_category: 'Form',
                    event_label: url,
                  });
                }
              }
            }
          },
        });
      });
    });
  });
}
