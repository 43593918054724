import $ from "../jquery";

var promise;

var recaptchaOptions;

/**
 * Load the reCAPTCHA API.
 *
 * @returns {Promise}
 */
function initPromise() {
  if (!promise) {
    promise = new Promise((resolve, reject) => {
      const el = document.createElement("script");
      el.onload = () => {
        const grecaptcha = window.grecaptcha;
        grecaptcha.ready(() => resolve(grecaptcha));
      };
      el.onerror = reject;
      el.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaOptions.siteKey}`;

      document.body.appendChild(el);
    });
  }

  return promise;
}

/**
 * Get a reCAPTCHA token.
 *
 * @returns {Promise<string>}
 */
export function getRecaptchaToken() {
  return initPromise().then((grecaptcha) => {
    return grecaptcha.execute(recaptchaOptions.siteKey, { action: "submit" });
  });
}

/**
 * Initialize reCAPTCHA.
 *
 * @param {string} sel element selector
 * @param {any} options reCAPTCHA options
 */
export default function initRecaptcha(sel, options) {
  if ($(sel).length) {
    recaptchaOptions = options;
    initPromise();
  }
}
