import $ from '../jquery';
import more from './more';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

$.views.converters('mmmyyyy', function (yyyymmdd) {
  const year = yyyymmdd.substring(0, 4);
  const month = yyyymmdd.substring(5, 7);

  return months[month - 1] + ' ' + year;
});

$.views.converters('placeholderImage', function (img) {
  var placeholder_url = $('.placeholder_image').val();
  if (!img) {
    return placeholder_url;
  }
  return img;
});

class SuccessStories {
  constructor(sel, options) {
    this.options = $.extend({ pageSize: 9 }, options);
    this.$container = $(sel);
    this.$wrapper = this.$container.find('.wrapper');
    this.$filters = this.$container.find('.filters :input').change(this.updateFilters.bind(this));
    this.$morebtn = $('<div class="load-more-block"><button class="load-more">Load More +</button></div>')
      .appendTo(this.$container)
      .click(this.more.bind(this))
      .hide();
    this.template = $.templates(this.$container.data('template'));

    this.$items = this.$container.find('.entry');
    this.showMore(this.$container.hasClass('has-more'));
    this.page = 2;
  }

  showMore(show) {
    this.hasMore = show;
    this.$morebtn.toggle(show);
  }

  more() {
    this.load(false);
  }

  /**
   * Load more items from the server.
   *
   * @param {boolean} replace remove all old items before showing new data
   */
  load(replace) {
    this.$container.addClass('loading');
    var filter_key = $('.stories .filter_key').val();
    var filter_sort = $('.stories .filter_sort').val();
    var filter_arr = {};
    if(filter_key !='' && filter_sort != '') {
      filter_arr[filter_key] = filter_sort;
    }

    more(
      'api/successStories',
      {
        page: this.page,
        filter: filter_arr,
        term_val: $('.stories .term_val').val()
      },
      (data) => {
        if (replace) this.clear();

        for (var i = 0; i < data.items.length; i++) {
          this.add(data.items[i]);
        }

        this.page++;
        this.showMore(data.more);
        this.$container.removeClass('loading');
      }
    );
  }

  add(data) {
    const html = this.template.render(data);
    const $el = $(html).appendTo(this.$wrapper);
    this.$items = this.$items.add($el);
  }

  clear() {
    this.$items.remove();
    this.$items = $();
    this.page = 1;
  }

  getFilters() {
    const filters = {};
    this.$filters.each(function () {
      if (this.value) filters[this.dataset.field] = this.value;
    });

    return filters;
  }

  updateFilters() {
    this.page = 1;
    this.load(true);
  }
}

/**
 * Initialize achievements wall.
 *
 * @param {string} sel container selector
 * @param {any} options SuccessStories options object
 */
export default function initSuccessStories(sel, options = {}) {
  const stories = [];

  $(sel).each(function (i, obj) {
    stories.push(new SuccessStories(obj, options));
  });

  return stories;
}
