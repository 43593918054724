import "../img/icons/email-icon-green.svg";
import "../img/icons/linkedin-light-blue.svg";
import "../img/people/team-andrew.png";
import "../img/people/team-anita.png";
import "../img/people/team-claire.png";
import "../img/people/team-dominic.png";
import "../img/people/team-hannah.png";
import "../img/people/team-julie-h.png";
import "../img/people/team-julie.png";
import "../img/people/team-katie.png";
import "../img/people/team-leanne.png";
import "../img/people/team-leigh.png";
import "../img/people/team-melanie.png";
import "../img/people/team-noel.png";
import "../img/people/team-paul.png";
import "../img/people/team-phil.png";
import "../img/people/team-rachel.png";
import "../img/people/team-sarah-scoffins.png";
import "../img/people/team-sarah.png";
import "../img/people/team-tom.png";
import "../img/people/team-tracy.png";
import "../img/people/team-gregory.png";
import "../img/people/team-su-bradd.png";
import "../img/people/team-andrea.png";
import "../img/people/team-nicola.png";
import "../img/people/team-david-hobbins.png";
import "../img/people/team-lee-harrison.png";
import "../img/people/team-siobhan.png";
import "../img/people/team-glen.png";
