import "./frontend/style.scss";
import "./manifest/favicon";
import "./manifest/layout";
import "./manifest/about";
import "./manifest/apprentices";
import "./manifest/apprenticeship-levy";
import "./manifest/blogs";
import "./manifest/courses";
import "./manifest/contact";
import "./manifest/e-learning";
import "./manifest/employers";
import "./manifest/index";
import "./manifest/learning-providers";
import "./manifest/team";
import "./manifest/giving-back";
import "./manifest/shop";
import "./manifest/extra";
import "./manifest/podcast";
import "./manifest/careers";
import "./manifest/events";
import "./manifest/success-stories";

import $ from "./jquery";
import "slick-carousel";

import initAchievementsWall from "./components/achievements";
import initAjaxForms from "./components/ajaxForm";
import initCheckout from "./components/checkout";
import initHashClicks from "./components/hashClick";
import initMenu from "./components/menu";
import initRecaptcha from "./components/recaptcha";
import initReview from "./components/review";
import initTabSections from "./components/tabSection";
import initWall from "./components/wall";
import initEvents from "./components/events";
import initSuccessStories from "./components/success-stories";

function initSliders() {
  $(".logos .container").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1076,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 751,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 351,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    asNavFor: ".slider-nav",
  });
  $(".slider-nav").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    arrows: false,
    fade: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    focusOnSelect: false,
  });

  $(".testimonial-slider").slick({
    centerMode: true,
    dots: true,
    arrows: false,
  });

  $(".partners-slider .partners").slick({
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 4000,
    pauseOnHover: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 476,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  $(".naw-2023-slider").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 5000,
    fade: true,
  });


  $(".naw-areas .slides").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    asNavFor: ".naw-areas-nav .split.content",
  });
  $(".naw-areas-nav .split.content").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".naw-areas .slides",
    arrows: false,
    // fade: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    focusOnSelect: true,
  });


  $(".our-services-slider").slick({
    slidesToShow: 3,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 476,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $(".our-services-naw-slider").slick({
    slidesToShow: 2,
    dots: true,
    infinite: true,
    autplay: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
}

$(() => {
  initMenu(".menu", ".menu-button");
  initTabSections(".js-tabs-section");

  $(".apprentice-category").on("click mouseover", function () {
    if ($(".apprentice-category").hasClass("active")) {
      $(".apprentice-category").removeClass("active");
    }
    $(".apprentice-sub").hide();
    var data = $(this).data("category");
    $(data).show();
    $(this).addClass("active");
  });

  $(".apprentice-courses .item").click(function () {
    $(this).next().toggleClass("active");
    $(this).toggleClass("active");
  });

  initHashClicks(".js-hash-click", ".js-trigger-hash-click", () =>
    $(".menu").removeClass("active")
  );
  initAjaxForms(".js-ajax-form");

  $(".js-read-more-course").click(function () {
    $(".readmore").toggleClass("inactive");

    if ($(".readmore").hasClass("inactive")) {
      $(this).text("Read More");
    } else {
      $(this).text("Read Less");
    }
  });

  $(".js-trigger-course-form").click(function () {
    $(".course-form").toggleClass("active");
  });

  initSliders();

  $(".js-search-trigger").click(function () {
    $(".search-field").toggleClass("active");
  });

  $(".js-menu-trigger").click(function () {
    $(".menu").toggleClass("active");
  });

  if ($(window).width() < 625) {
    $(".information-advice-content .accordion").removeClass("active");
  }

  $(".js-accordion-trigger").click(function () {
    $(this).parent().toggleClass("active");
  });

  $(".js-view-courses").click(function () {
    $(this).parent().parent().toggleClass("active");
  });

  $('.index-popout, .index-popout .close').click(function(){
    $('.index-popout').removeClass('active');
  });
  
  if ($(window).width() <= 425) {
    $(".js-view-category-courses").click(function () {
      $(this).parent().toggleClass("active");
    });
  }

  initWall(".testimonial-wall");
  initAchievementsWall(".js-achievements-wall");
  initEvents(".js-event");
  initSuccessStories(".stories");

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 1) {
      $(".navigation").addClass("shrink");
    } else {
      $(".navigation").removeClass("shrink");
    }
  });

  $(".js-smooth").click(function (e) {
    // Figure out element to scroll to
    var target = $(this.hash);
    if (target.length) {
      // Stop the browser setting hash
      e.preventDefault();
      $("html, body").animate({ scrollTop: target.offset().top - 90 }, 500);
    }
  });

  initRecaptcha(".js-add-recaptcha", {
    siteKey: "6LeVDa0UAAAAAIp4lddoSJ1CGhuODZOOopSojDmR",
  });

  initCheckout();
  initReview();

  $('.filter-btn').click(function() {
      $('.filter-section').find('.dropdown-wrapper').toggle();
  });

  $('.podcasts .search-input').on('keypress', function(e) {
    if(e.which == 13 && $(this).val().length > 2) {
        location.href= '/podcast?term=' + $(this).val();
    }
  });

  $('.stories .search-input').on('keypress', function(e) {
    if(e.which == 13 && $(this).val().length > 2) {
        location.href= '/success-stories?term=' + $(this).val();
    }
  });

  $('.podcasts').find('.search-icon').click(function() {
    if($('.search-input').val().length > 2) {
      location.href= '/podcast?term=' + $('.search-input').val();
    }
  });

  $('.stories').find('.search-icon').click(function() {
    if($('.search-input').val().length > 2) {
      location.href= '/success-stories?term=' + $('.search-input').val();
    }
  });

});

