import "../img/icons/facebook-blue.svg";
import "../img/icons/linkedin-blue.svg";
import "../img/icons/menu-close-blue.svg";
import "../img/icons/menu-close-white.svg";
import "../img/icons/search-blue.svg";
import "../img/icons/search-grey.svg";
import "../img/icons/search-white.svg";
import "../img/icons/twitter-blue.svg";
import "../img/icons/white-arrow-left.svg";
import "../img/logo-dark.svg";
import "../img/logo-light.svg";
