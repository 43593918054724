import $ from "../jquery";

/**
 * Set up interactive menus.
 *
 * @param {string} sel menu container selector
 * @param {string} buttons button selector
 */
export default function initMenu(sel, buttons) {
  const $menu = $(sel),
    $left = $menu.find(".left"),
    $leftItems = $left.find(".item"),
    $right = $menu.find(".right"),
    $container = $right.find(".menu-container"),
    $first = $container.first(),
    $closeImg = $menu.find(".close img");

  function menuSwaps() {
    const $target = $($(this).data("menu"));

    $leftItems.removeClass("active");
    $(this).addClass("active");

    $container.removeClass("active");

    $left.toggleClass("inactive");
    $right.toggleClass("active");
    $closeImg.toggleClass("active");

    if ($target.length) {
      $target.addClass("active");
    } else {
      $first.addClass("active");
    }
  }

  $(buttons).click(menuSwaps);
}
