/**
 * Listen for an event.
 *
 * @param {string} name event name
 * @param {EventListener} callback event callback
 * @param {HTMLElement} target event target
 * @param {AddEventListenerOptions} options listener options
 */
export default function listen(name, callback, target = window, options = {}) {
  target.addEventListener(name, callback, options);
}
