import '../img/100in100.png';
import '../img/apprentice-icon.svg';
import '../img/employer-icon.svg';
import '../img/homepage/employers-overlap.png';
import '../img/homepage/homepage-employers-overlay.svg';
import '../img/homepage/homepage-slider-1.png';
import '../img/homepage/homepage-slider-2.png';
import '../img/homepage/homepage-slider-3.png';
import '../img/homepage/homepage-slider-award.png';
import '../img/homepage/homepage-testimonial-overlap.svg';
import '../img/homepage/services-fe.svg';
import '../img/homepage/services-hat.svg';
import '../img/homepage/services-stars.svg';
import '../img/logos/aelp.png';
import '../img/logos/apprenticeships.png';
import '../img/logos/cyber.png';
import '../img/logos/esfa.png';
import '../img/logos/eu-esf.png';
import '../img/logos/feo.png';
import '../img/logos/iso-9001.png';
import '../img/logos/pride-in-fe.png';
import '../img/icons/podcasts-blue-icon.svg';
import '../img/logos/ofsted.png';
import '../img/homepage/open-icon.svg';
import '../img/homepage/instagram-heading-logo.png';


// NAW
import '../img/homepage/naw/16th.png';
import '../img/homepage/naw/week.png';
import '../img/homepage/naw/naw-pink.png';
import '../img/homepage/naw/naw-white.png';
import '../img/homepage/naw/skills-colour.png';
import '../img/homepage/naw/skills-white.png';
import '../img/homepage/naw/spring-colour.png';
import '../img/homepage/naw/spring-white.png';

import '../img/homepage/naw/naw-banner-image-1.png';
import '../img/homepage/naw/naw-banner-image-2.png';
import '../img/homepage/naw/naw-banner-image-3.png';

import '../img/naw/services-apprentice.svg';
import '../img/naw/services-employer.svg';
import '../img/naw/why-choose.png';
import '../img/naw/single-apprentice.png';
import '../img/naw/single-employer.png';

import '../img/naw/engineering-background.svg';
import '../img/naw/engineering-icon.svg';
import '../img/naw/education-background.svg';
import '../img/naw/education-icon.svg';
import '../img/naw/logistics-background.svg';
import '../img/naw/logistics-icon.svg';
import '../img/naw/child-care-background.svg';
import '../img/naw/child-care-icon.svg';
import '../img/naw/video.png';
