import $ from "../jquery";
import listen from "./listenEvent";
import more from "./more";

class Wall {
  constructor(sel, options) {
    this.$container = $(sel);

    const tagOptions = this.$container.data("wall") || {};
    const resizeme = this.resized.bind(this);

    this.options = $.extend({}, Wall.defaults, options, tagOptions);
    $(window).resize(resizeme);

    this.items = [];
    this.resized();

    this.$container.find("> div").each((_i, o) => this.add(o));

    if (this.$container.data("load-more")) {
      this.pageSize = this.items.length;
      this.$more = $('<div class="load-more-btn"><p>Load more</p></div>')
        .insertAfter(this.$container)
        .click(this.loadMore.bind(this));
    }

    listen("typekit-loaded", () => window.requestAnimationFrame(resizeme));
  }

  updateColumns(settings) {
    this.settings = settings;

    const pads = this.settings.columns - 1;
    const totalPadding =
      this.settings.padding * pads + this.settings.outerPadding * 2;
    const multiple = (this.width - totalPadding) / this.settings.columns;
    this.itemWidth = multiple;

    this.height = 0;
    this.columnLefts = [];
    for (var i = 0; i < this.settings.columns; i++) {
      this.columnLefts.push(
        this.settings.outerPadding +
          (multiple + this.settings.padding) * i +
          "px"
      );
    }

    this.columnHeights = [];
    for (var j = 0; j < this.settings.columns; j++) {
      this.columnHeights.push(this.settings.outerPadding);
    }

    this.rearrange();
  }

  shortestColumn() {
    var best = 0;
    var bestHeight = this.columnHeights[0];

    for (var i = 1; i < this.settings.columns; i++) {
      if (this.columnHeights[i] < bestHeight) {
        bestHeight = this.columnHeights[i];
        best = i;
      }
    }

    return best;
  }

  setColumnHeight(col, height) {
    this.columnHeights[col] = height;

    if (height > this.height) {
      this.height = height;
      this.$container.css("height", height + "px");
    }
  }

  resized() {
    this.width = this.$container.innerWidth();

    for (var i = 0; i < this.options.responsive.length; i++) {
      var entry = this.options.responsive[i];
      if (this.width >= entry[0]) {
        this.updateColumns(entry[1]);
        return;
      }
    }
  }

  rearrange() {
    for (var i = 0; i < this.items.length; i++) {
      const $item = this.items[i];
      this.place($item);
    }
  }

  add(item) {
    const $item = $(item);

    this.$container.append($item.addClass("item"));
    this.items.push($item);
    this.place($item);
  }

  place($item) {
    const padding = $item.innerWidth() - $item.width();
    $item.css("width", this.itemWidth - padding + "px");

    const col = this.shortestColumn();
    const ih = $item.innerHeight();
    const left = this.columnLefts[col];
    const height = this.columnHeights[col];

    $item
      .css("position", "absolute")
      .css("top", height + "px")
      .css("left", left);

    this.setColumnHeight(col, height + ih + this.settings.padding);
  }

  loadMore() {
    more(
      "testimonials/more",
      {
        min: this.items.length,
        max: this.items.length + this.pageSize,
      },
      (data) => {
        for (var i = 0; i < data.posts.length; i++) {
          this.add(data.posts[i]);
        }

        if (!data.more) {
          this.$more.remove();
        }
      }
    );
  }
}

Wall.defaults = {
  responsive: [
    [
      1200,
      {
        columns: 2,
        outerPadding: 25,
        padding: 50,
      },
    ],
    [
      670,
      {
        columns: 2,
        outerPadding: 30,
        padding: 20,
      },
    ],
    [
      400,
      {
        columns: 1,
        outerPadding: 20,
        padding: 15,
      },
    ],
    [
      0,
      {
        columns: 1,
        outerPadding: 0,
        padding: 20,
      },
    ],
  ],
};

/**
 * Initialize all walls.
 *
 * @param {string} sel element selector
 * @param {any} options Wall objects
 */
export default function initWall(sel, options) {
  var walls = [];

  $(sel).each(function (i, obj) {
    walls.push(new Wall(obj, options));
  });

  return walls;
}
