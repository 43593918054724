import $ from "../jquery";

/**
 * Create a Tab Section.
 *
 * @param {string|Element} el selector or element
 */
function tabSection(el) {
  var $me = $(el);
  var $tabs = $me.find(".js-tab");
  var $sections = $me.find(".js-tab-content");

  $tabs.click(function (e) {
    var $tab = $(e.currentTarget);
    $tabs.removeClass("active");
    $tab.addClass("active");

    var i = $tabs.index($tab);
    $sections.removeClass("active");
    $sections[i].classList.add("active");
  });
}

/**
 * Initialize all Tab Sections.
 *
 * @param {string} sel selector
 */
export default function initTabSections(sel) {
  $(sel).each(function (_i, el) {
    tabSection(el);
  });
}
