import $ from "../jquery";

export default function initCheckout() {
  const $checkout = $(".js-checkout");

  if ($checkout.length) {
    const $signIn = $(".js-sign-in");
    const $register = $(".js-register");
    const $guest = $(".js-as-guest");
    const $operation = $("#js-operation");
    const $passwords = $(".js-passwords");
    const $passwordFields = $passwords.find('input');
    const $title = $(".js-checkout-title");

    $passwordFields.removeAttr('required');

    $register.on("click", () => {
      $signIn.hide();
      $passwords.show();
      $passwordFields.attr('required', 'required');
      $checkout.show();
      $operation.val("register");
      $title.text("Create Account");
    });

    $guest.on("click", () => {
      $signIn.hide();
      $passwords.hide();
      $checkout.show();
      $operation.val("guest");
      $title.text("Continue as Guest");
    });
  }
}
