import $ from "../jquery";

/**
 * Get more data from an AJAX endpoint
 *
 * @param {string} path AJAX url
 * @param {any} args data to pass
 * @param {(data: any) => void} callback callback function
 */
export default function more(path, args, callback) {
  $.post(window.PathPrefix + path, args, callback, "json");
}
