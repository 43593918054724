import $ from "../jquery";

function addField($parent, label, name) {
  const $row = $('<div class="row">').appendTo($parent);
  const $label = $(`<label>${label}</label>`).appendTo($row);
  const $field = $(`<input name="extra[${name}]">`).appendTo($label);
  return $field;
}

export default function initReview() {
  const $form = $("#js-review-form");
  if (!$form.length) return;

  const $error = $form.find(".general-error");
  const $choices = $("input[name=method]");
  const $extras = $(".js-method-extra");
  const stripe = Stripe(
    "pk_live_51I1XqxDNzwdJuFVwfVA2HvSIMnrC6OAT2KqXWICYQSN3xVfUX4HhnGjpcMezyqoxye2umcWMOU5e3oheTMtYGGfI003CuD2RG5"
  );
  const elements = stripe.elements();
  const stripeCard = elements.create("card", { hidePostalCode: true });
  var method = $choices.filter(":checked").val();

  function updateMethod(newMethod) {
    method = newMethod;
    const $extra = $(`#${method}-extra`);
    $extras.empty();

    switch (method) {
      case "Dummy":
        addField($extra, "Name on Card", "name");
        addField($extra, "Card Number", "number");
        addField($extra, "CCV", "ccv");
        addField($extra, "Expires (month)", "expiryMonth");
        addField($extra, "Expires (year)", "expiryYear");
        break;

      case "Stripe":
        stripeCard.mount($extra.get(0));
        break;
    }
  }

  $choices.on("change", (e) => updateMethod(e.target.value));
  if (method) updateMethod(method);

  function stripeTokenHandler(token) {
    $('<input name="extra[token]" type="hidden">')
      .val(token.id)
      .appendTo($form);
    $form.get(0).submit();
  }

  $form.on("submit", (e) => {
    $error.text("");

    if (!method) {
      e.preventDefault();
      $error.text("No method chosen.");
      return;
    }

    switch (method) {
      case "Stripe":
        e.preventDefault();
        $error.text("Talking to Stripe...");
        stripe.createToken(stripeCard).then((result) => {
          if (result.error) {
            // Inform the customer that there was an error.
            if(result.error.code == "invalid_number")
            {
              $error.text('Payment unsuccessful, please try again.');
            }
            else
            {
              $error.text(result.error.message);
            }
          } else {
            // Send the token to your server.
            $error.text("Submitting your payment...");
            stripeTokenHandler(result.token);
          }
        });
        break;
    }
  });
}
