import $ from "../jquery";
import more from "./more";

class Events {
  constructor(sel, options) {
    this.options = $.extend({ pageSize: 9 }, options);
    this.$container = $(sel);
    this.$wrapper = this.$container.find(".wrapper");
    this.$morebtn = $('<button class="item contact-btn btn green-bg blue">Load More</button>')
      .appendTo(this.$container.find('.load-more-block'))
      .click(this.more.bind(this))
      .hide();
    this.template = $.templates(this.$container.data("template"));

    this.$items = this.$container.find(".entry");
    this.showMore(this.$container.hasClass("has-more"));
    this.page = 2;
  }

  showMore(show) {
    this.hasMore = show;
    this.$morebtn.toggle(show);
  }

  more() {
    this.load(false);
  }

  /**
   * Load more items from the server.
   *
   * @param {boolean} replace remove all old items before showing new data
   */
  load(replace) {
    this.$container.addClass("loading");
    more(
      "api/events",
      {
        page: this.page,
      },
      (data) => {
        console.log(data);
        for (var i = 0; i < data.items.length; i++) {
          this.add(data.items[i]);
        }

        this.page++;
        this.showMore(data.more);
        this.$container.removeClass("loading");
      }
    );
  }

  add(data) {
    var html = '';
    var event_type = (data.event_type == 1) ? 'hosted-event' : 'attending-event';
    html = '<div class="entry '  + event_type + '">';
    if(data.event_thumbnail && data.event_thumbnail != '') {
      html += '<div class="image" style="background-image: url('+ data.event_thumbnail +');"></div>';
    }
    html +='<div class="copy">'+
                    '<p class="event-title">'+ data.event_title +'</p>'+
                    '<p class="event-place">'+ data.event_place +'</p>'+
                    '<p class="event-date">'+ data.event_date +'</p>'+
                    '<p class="event-short-description">'+ data.short_description +'</p>'+
                    '<p class="event-description">'+ data.long_description +'</p>' +
            '</div>' + 
            '<div class="btn-container">';

    var btn_class = (event_type == 'hosted-event') ? "item contact-btn btn blue-bg white" : "item contact-btn btn green-bg blue";
    var btn_redirect_anchor = (data.is_disabled_event != "") ? "javascript:void(0)" : "/contact"; 
    html +='<a href="'+btn_redirect_anchor+'" class="' + btn_class +'" '+ data.is_disabled_event +'>Find out more</a>';
                    
    html +=   '</div>'+
            '</div>';
    const $el = $(html).appendTo(this.$wrapper);
    this.$items = this.$items.add($el);
  }

  clear() {
    this.$items.remove();
    this.$items = $();
    this.page = 1;
  }

  loadEventDate(data) {
    if(data.end_event_date && data.end_event_date !='') {

    } else {
      return data.start_event_date;
    }
  }
}

/**
 * Initialize achievements wall.
 *
 * @param {string} sel container selector
 * @param {any} options AchievementWall options object
 */
export default function initEvents(sel, options = {}) {
  const events = [];

  $(sel).each(function (i, obj) {
    events.push(new Events(obj, options));
  });

  return events;
}
